import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Guide">
    <section className="section-guide">

      <h5>Here’s a step-by-step guide on how to use the app:</h5>
      <ol>
        <li>Android users can download the application from Play Store by searching for ‘Parakh’. or <a href="https://play.google.com/store/apps/details?id=me.parakh.edu" target="_blank" rel="noopener noreferrer" >click here</a> </li>
        <li>Install the application and enter your mobile number. The app will prompt you to verify your phone number using an SMS. Click on verify the phone number and wait for the verification process to be completed.</li>
        <li>Once the verification is complete, the app will ask you to select your language.</li>
        <li>After the language is selected, then create your profile.</li>
        <li>The app display two options for registration - 'school staff' OR 'student/parent/guardian'.</li>
        <li>If you select student/parent/guardian option, your setup is done.</li>
        <li>If you select 'school staff' after that enter your school DISE code and click on school search button.</li>
        <li>Enter the basic information of your school. Click the button at the bottom right.</li>
        <li>You can select a number of classes, section and go to the next step.</li>
        <li>Add your school staff member and your setup done.</li>
        <li>Based on user access, the app display many options like Dashboard, Social, Enrollment, Attendance, Evaluation, Child Safety, Finance, Office, Library.</li>
        <li>If you encounter a problem with our module, then please go to the Help section and every option have info, guide more about our application thank you.</li>
      </ol>


    </section>
  </Layout>
);

export default Page;

